<div class="container">
  <div class="item">
    <app-sidebar></app-sidebar>
  </div>
  <div class="item main-page">
    <app-navbar></app-navbar>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </div>
</div>
