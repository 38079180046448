<div class="about">
<p class="page-title">About Us</p>
<p> Our goal is provide seamless login experience so that users do not have to create an account or download an App to order pizza or connect with their favorite retailers. Also, Users should not have to compromise on their privacy and be subjected unsolicited tracking. </p>
<p> We believe</p>
<ul>
    <li>Privacy is important - We will never track your activity or sell your data. </li>
    <li>Time is finite - Do what you love. No distracting Ads ever. </li>
    <li>Users should not have to create a login for every retailer they interact with, and should not rely on social login platforms that are built around selling ads. </li>
    <li>Our revenue model is based on # of successful logins by merchant. Most small retailers will fall within the free tier(say less than 1000 logins a month) and will not incur any charges.</li>
</ul>  
</div>