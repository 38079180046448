<div class="home">
<form [formGroup]="userForm" (ngSubmit)="onSubmit()" #myUserForm="ngForm" class="text-center border border-light p-5">

    <p class="page-title">Sign Up</p>
  
  
    <p class="warning-msg" *ngIf="myUserForm.submitted && registrationResponse != null">{{registrationMessage}}</p>

    <div class="form-row mb-4">
        <div class="col">
            <input type="text" formControlName="firstName" id="defaultRegisterFormFirstName" class="form-control" placeholder="First name">
            <p class="warning-msg" *ngIf="userForm.get('firstName').hasError('required') && myUserForm.submitted">Warning: First name is required</p>
        </div>
        <div class="col">
            <input type="text" formControlName="lastName" id="defaultRegisterFormLastName" class="form-control" placeholder="Last name">
            <p class="warning-msg" *ngIf="userForm.get('lastName').hasError('required') && myUserForm.submitted">Warning: Last name is required</p>
        </div>
        <div class="col">
          <input type="email" formControlName="email" id="defaultRegisterFormEmail" class="form-control mb-4" placeholder="E-mail">
          <p class="warning-msg" *ngIf="userForm.get('email').hasError('required') && myUserForm.submitted">Warning: Email is required for registration confirmation</p>
          <p class="warning-msg" *ngIf="userForm.get('email').hasError('email') && myUserForm.submitted">Warning: Please enter valid email</p>
        </div>
        <div class="col">
          <input type="text" formControlName="phone" id="defaultRegisterFormPhone" class="form-control mb-4" placeholder="+17031231234">
          <p class="warning-msg" *ngIf="userForm.get('phone').hasError('required') && myUserForm.submitted">Warning: Phone number is required</p>
        </div>
        
        <div class="col">
          <input type="password" formControlName="password" id="defaultRegisterFormPassword" class="form-control mb-4" placeholder="Password">
          <p class="warning-msg" *ngIf="userForm.get('password').hasError('required') && myUserForm.submitted">Warning: Password is required</p>
        </div>
        <div class="col">
          <button class="btn btn-info my-4 btn-block" type="submit">Sign Up</button>
        </div>  
    </div>
  </form>
</div>  