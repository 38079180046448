import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  userForm;
  registrationMessage = null;
  registrationResponse = null;

  constructor(private formBuilder: FormBuilder, private http: HttpClient) { }

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern('^[a-zA-Z]+$')]],
      lastName: ['', [Validators.required, Validators.pattern('^[a-zA-Z]+$')]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  onSubmit() {
    if(this.userForm.valid){
      this.http.post('https://ge3ed62fjl.execute-api.us-east-1.amazonaws.com/dev/register', this.userForm.value)
      .subscribe((response)=>{
        this.registrationResponse = response;
        console.log('repsonse ',response);

        if (this.registrationResponse.userConfirmed == false && this.registrationResponse.codeDeliveryDetails.DeliveryMedium == "EMAIL") {
          // user registered successfully and should verify email.
          this.registrationMessage = "Sign Up Successful ! However, To complete registration, Please check your email and click on the link provided to confirm the email account belongs to you."
        } else {
          this.registrationMessage = this.registrationResponse.message;
        }
      })
    } else {
      this.registrationMessage = "Sign up Failed !"
    }

  }


}
